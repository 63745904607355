const indicators = {
  "ti": "time",
  "phAwcNAVuyj0TAlJeCEzcGQ": "children_per_woman_total_fertility",
  "phAwcNAVuyj1gkNuUEXOGag": "co2_emissions_tonnes_per_person",
  "phAwcNAVuyj1jiMAkmq1iMg": "income_per_person_gdppercapita_ppp_inflation_adjusted",
  "0ArfEDsV3bBwCcGhBd2NOQVZ1eWowNVpSNjl1c3lRSWc": "child_mortality_0_5_year_olds_dying_per_1000_born",
  "phAwcNAVuyj2tPLxKvvnNPA": "life_expectancy_years",
  "tFOn62dEO9QCyIKK6kgSYRQ": "aid_given_2007_us",
  "tGTst0WEm8V8zI9LOYvGmTg": "aid_given_per_person_2007_us",
  "tQR7RhlZdPjBkVCDPPF4zUg": "aid_given_percent_of_gni",
  "tXn3DSfvsYujaBP9bvH6acg": "water_and_sanitation_aid_given_percent_of_aid",
  "tMxqFNS7BC5QyLrhBO8DXqQ": "economical_infrastructure_aid_given_percent_of_aid",
  "tMjW0fdVf9VJaxVk_VFSUhg": "production_sector_aid_given_percent_of_aid",
  "tBJ1rYQ-nA6fqI6_Gn3mBNg": "multisector_cross_cutting_aid_given_percent_of_aid",
  "tQQaILpdu-vGtSkJLr2VQCw": "education_aid_given_percent_of_aid",
  "tRybjVoG5Ah9yhKcEx16u5Q": "health_aid_given_percent_of_aid",
  "tjFf_YqwB6tgSG9L0r0Ywdg": "population_policies_aid_given_percent_of_aid",
  "t3IAEOsfHK-z6rvGLCDR74g": "government_and_society_aid_given_percent_of_aid",
  "tqjUijP4mi_dHKkCZjOn0oA": "other_social_services_aid_given_percent_of_aid",
  "0AkBd6lyS3EmpdHVuNVpKdnNCa08yV3NOd0Zsal9JaWc": "aid_received_total_us_inflation_adjusted",
  "t9GL1nIZdtxszJbjKErN2Hg": "aid_received_per_person_current_us",
  "tzK6dx2JltRfVXFI1ADh84w": "aid_received_percent_of_gni",
  "tAQpQeQkuyA1-ZovdDJ7JAw": "dollar_billionaires_per_million_people",
  "t_KhYe1qTGh4c90N61AUDSg": "average_age_of_dollar_billionaires_years",
  "tNWhbu-1UIPPxtmRHtnINOQ": "total_number_of_dollar_billionaires",
  "0AkBd6lyS3EmpdDlSTTVWUkU3Z254aEhERmVuQWZaeWc": "debt_to_foreigners_by_public_and_private_percent_of_gni",
  "0AkBd6lyS3EmpdC1iMVRuVUFUd08tVDM0ZDF0cnFtekE": "total_reserves_percent_of_debt_to_foreigners",
  "pyj6tScZqmEfI4sLVvEQtHw": "total_gdp_us_inflation_adjusted",
  "0AkBd6lyS3EmpdHo5S0J6ekhVOF9QaVhod05QSGV4T3c": "gdppercapita_us_inflation_adjusted",
  "rcTO3doih5lvJCjgLSvlajA": "gdpperemployee_us_inflation_adjusted",
  "r6kTHMinnVedj8gPsUtfZ0g": "gdpperworking_hour_us_inflation_adjusted",
  "tvllZwGIbhwxLD7EXFhPeXQ": "gdppercapita_growth_over_next_10_years",
  "0AkBd6lyS3EmpdEdDWHhBcFpjMUo4MGE2X2Q4WXFQRGc": "gdppercapita_growth_percent_per_year",
  "0ArfEDsV3bBwCdFl6cDkxcmZxM0pVNXBUYjE1ZmNqVUE": "total_gni_ppp_current_international",
  "0ArfEDsV3bBwCdFdqZ0NOdjluMmoyUTBTWTRjWWQzQVE": "gnipercapita_constant_2000_us",
  "0ArfEDsV3bBwCdFVrVDZQUnRwZ2lqT2lPMXcySXZwRmc": "gnipercapita_atlasmethod_current_us",
  "0ArfEDsV3bBwCdGhJcHAwanc2aFdZeXl1WTVZQnJjb1E": "gnipercapita_ppp_current_international",
  "pyj6tScZqmEcjeKHnZq6RIg": "inequality_index_gini",
  "tmKvydPl_roGIQBrMYA6C4g": "income_share_of_richest_10percent",
  "tLnCxItXzRSu9gH-5PyEFDw": "income_share_of_richest_20percent",
  "twSOUYrIFh2W2snDUt7VaQg": "income_share_of_2nd_richest_20percent",
  "t_-14NtXH6xZX48xHG75z5w": "income_share_of_middle_20percent",
  "tXRyZGCfHsWMmr53VFxrqTw": "income_share_of_2nd_poorest_20percent",
  "pyj6tScZqmEdIyrBS31XAaw": "income_share_of_poorest_20percent",
  "trzLWJQU4SZMDpeVg3XnL5A": "income_share_of_poorest_10percent",
  "0AkBd6lyS3EmpdGJoOUJXalk3STFYUG85MkxlbnQxMmc": "inflation_annual_percent",
  "pyj6tScZqmEejn8qHNmm4LQ": "central_bank_discount_rate_annual_percent",
  "0AkBd6lyS3EmpdG9sVVF6dHpGdnhQU3BkMlAtNHFwVkE": "investments_percent_of_gdp",
  "0AkBd6lyS3EmpdFgzT1ZJWW4tdDB4Q2NETTVoTG1ZYlE": "tax_revenue_percent_of_gdp",
  "0AkBd6lyS3EmpdE03VFhRMnBpMGZhQ19Vbk9pMGU5VUE": "foreign_direct_investment_net_inflows_percent_of_gdp",
  "0AkBd6lyS3EmpdHQtSzBhVXA2WTNrVDFleUZvZ0doTUE": "foreign_direct_investment_net_outflows_percent_of_gdp",
  "pyj6tScZqmEd5FA9xlfO9eA": "patent_applications_total",
  "pyj6tScZqmEdMioz5VJKXHw": "patents_granted_total",
  "pyj6tScZqmEe371ZVZl73eA": "patents_in_force_total",
  "tBrbR3BlR_12WlTIlSTpu6g": "poverty_percent_people_below_2_a_day",
  "trbzCrl1eb6QJG5D8j1-qQw": "rural_poverty_percent_rural_people_below_national_rural",
  "tublssyj-uqIY25OoRupbCw": "urban_poverty_percent_urban_people_below_national_urban",
  "t1YAVXUoD3iJKy2mSq2Padw": "extreme_poverty_percent_people_below_125_a_day",
  "0AkBd6lyS3EmpdFhPbDdCTTYxM1dGc21UdE9sSkp1WEE": "agriculture_percent_of_gdp",
  "0AkBd6lyS3EmpdHA2UEFOYTlUTWtzV29xbHFuMU00SFE": "industry_percent_of_gdp",
  "0AkBd6lyS3EmpdHk4eXd4RG5Rb1gtUTB0cUJ3M21qdGc": "services_percent_of_gdp",
  "0AkBd6lyS3EmpdHZSTVMxaVdxQlFLR3NMbnBEWnVuTXc": "exports_percent_of_gdp",
  "0AkBd6lyS3EmpdEhLMVdnUjZ0d05WWkhjT0FjSDIwQmc": "imports_percent_of_gdp",
  "0AkBd6lyS3EmpdGpkU3BSVmw5UXhTMWd6UFc1eXI3Rnc": "arms_exports_us_inflation_adjusted",
  "0AkBd6lyS3EmpdEljeENrOXlFXzR3Rm8xT0drTV9YclE": "arms_imports_us_inflation_adjusted",
  "0Asm_G8nr4TCSdDh2NWQtVDJhYlVsTElFRjJIYkNlSnc": "total_gdp_ppp_inflation_adjusted",
  "0AkBd6lyS3EmpdEZkTFJZR2RNMVFuRmUzbktyTkoxREE": "high_technology_exports_percent_of_manufactured_exports",
  "0AkBd6lyS3EmpdDJFSzRHa3g1Q29BOWlla0tTOEFyVGc": "merchandise_trade_percent_of_gdp",
  "0AkBd6lyS3EmpdEF6VzlKTzNCNjRnT0ZzMDg5a1d1Z3c": "trade_balance_us_not_inflation_adjusted",
  "0AkBd6lyS3EmpdFpGU185SkpmZ2V4ajNPZHFaaEwtU1E": "trade_balance_percent_of_gdp",
  "rEF20Sw6Sy7tn4DKsKSDDMQ": "hourly_compensation_us",
  "rdCufG2vozTpKw7TBGbyoWw": "working_hours_per_week",
  "pyj6tScZqmEfMkeuokDLVIQ": "market_value_of_listed_companies_percent_of_gdp",
  "0AkBd6lyS3EmpdDNPQjFBT2s5Zko3U2V0NFQzS3owRnc": "military_expenditure_percent_of_gdp",
  "phAwcNAVuyj3Iw3kqbjJTZQ": "math_achievement_4th_grade",
  "phAwcNAVuyj3fwfA8XA25Eg": "math_achievement_8th_grade",
  "pyj6tScZqmEcWM3hb0x-BZA": "ratio_of_girls_to_boys_in_primary_and_secondary_education_perc",
  "0AkBd6lyS3EmpdE8xR0dUWDI4ME02SjQ5bi1NYnFHN0E": "ratio_of_young_literate_females_to_males_percent_ages_15_24",
  "pyj6tScZqmEc96gAEE60-Zg": "literacy_rate_adult_female_percent_of_females_ages_15_above",
  "pyj6tScZqmEd4fn4YYOvuOg": "literacy_rate_adult_male_percent_of_males_ages_15_and_above",
  "pyj6tScZqmEdrsBnj2ROXAg": "literacy_rate_adult_total_percent_of_people_ages_15_and_above",
  "pyj6tScZqmEf96wv_abR0OA": "literacy_rate_youth_female_percent_of_females_ages_15_24",
  "pyj6tScZqmEe7OxrqKcSWfw": "literacy_rate_youth_male_percent_of_males_ages_15_24",
  "pyj6tScZqmEepmgV0TLjBag": "literacy_rate_youth_total_percent_of_people_ages_15_24",
  "0AkBd6lyS3EmpdGVSdkZnZWhfMDYybzd6U2p3NkxsZ3c": "children_out_of_school_primary",
  "0AkBd6lyS3EmpdFNPaEhiUDJ0QWZYOEwwQlBSTmtza1E": "children_out_of_school_primary_female",
  "0AkBd6lyS3EmpdFd5cnQzNU5IYm1vTWtrTWRIX3UxbHc": "children_out_of_school_primary_male",
  "0AkBd6lyS3EmpdEhTN2hlZ05ZczVwZDdVZlF5cUxJb2c": "primary_completion_rate_total_percent_of_relevant_age_group",
  "0AkBd6lyS3EmpdGhCWnZrTGMwaTl5ek9QS0szMTIwcEE": "primary_school_completion_percent_of_boys",
  "0AkBd6lyS3EmpdFVxSEVZVWE1b0l6NWo5NzNTZ2IzWVE": "primary_school_completion_percent_of_girls",
  "0AkBd6lyS3EmpdFJTUEVleTM0cE5jTnlTMk41ajBGclE": "expenditure_per_student_primary_percent_of_gdp_per_person",
  "0AkBd6lyS3EmpdDBuUVIzbWwtaU5helpJVG5BMmxyX1E": "expenditure_per_student_secondary_percent_of_gdp_per_person",
  "0AkBd6lyS3EmpdDJxdlN6cEtYMjMxdC1XdGdKOXR2bkE": "expenditure_per_student_tertiary_percent_of_gdp_per_person",
  "0ArfEDsV3bBwCdHFEVVhHZElzb0VRWE9pc3JmZHg2dWc": "mean_years_in_school_women_25_years_and_older",
  "0ArfEDsV3bBwCdC03X1ZWNGJHR3FBb0Q3VjJIdV9OSmc": "mean_years_in_school_men_25_years_and_older",
  "0ArfEDsV3bBwCdC1MYzAtY2xPQ2xOR1lMeGhYSWlpR0E": "mean_years_in_school_women_25_to_34_years",
  "0ArfEDsV3bBwCdHlYZHNWN1YtWVNudU9UbWJOd19nUVE": "mean_years_in_school_men_25_to_34_years",
  "0ArfEDsV3bBwCdEttYjNNUTlkUTdrMUQ0c3BTR0dINlE": "mean_years_in_school_women_of_reproductive_age_15_to_44",
  "0ArfEDsV3bBwCdG5JUDZjTHR5SzZFZjBqa2JYYUxBclE": "mean_years_in_school_women_percent_men_25_to_34_years",
  "0AkBd6lyS3EmpdHd2Nld0NEVFOGRiSTc0V3ZoekNuS1E": "energy_use_total",
  "0AkBd6lyS3EmpdHRmYjJWLVF0SjlQY1N5Vm9yU0xxaGc": "energy_use_per_person",
  "0AkBd6lyS3EmpdFNZMXZwcjNPY2c3MWwxbWIwVFgyd0E": "energy_production_total",
  "0AkBd6lyS3EmpdHJ6UTV4MkEyN0NYdnJJOG1oYUxmTWc": "energy_production_per_person",
  "pyj6tScZqmEdz8B4njtoHPA": "pump_price_for_gasoline_us_per_liter",
  "0Auk0ddvGIrGqcHlqNnRTY1pxbUVjMVRtTWlGZG1PVmc": "coal_consumption_total",
  "0Auk0ddvGIrGqcHlqNnRTY1pxbUVka1hObHlPbTlmUUE": "coal_consumption_per_cap",
  "tiVeyAJd7iRWorOwl_ARWEQ": "electricity_use_per_person",
  "tEu78F4acf0u6MRyhg5-9qQ": "electricity_use_total",
  "t7SFNscT9Ex0s9i3av7PxRQ": "residential_electricity_use_per_person",
  "teUZEfKw52HewO3D0YrQ5HA": "residential_electricity_use_total",
  "pyj6tScZqmEehRG-9mMHYdg": "electricity_generation_total",
  "pyj6tScZqmEeMtYNdMyLKOw": "electricity_generation_per_person",
  "t1MShlv870O6LmFNEHazdEg": "hydro_power_generation_total",
  "tSjVrGemv30eCh3jPZkXYCQ": "hydro_power_generation_per_person",
  "trRb8ZIaBOD4KzikShshZ2g": "nuclear_power_generation_total",
  "t28UhT9IaWINamciSiJIS7w": "nuclear_power_generation_per_person",
  "pyj6tScZqmEfv2K6dZmskWg": "natural_gas_production_total",
  "pyj6tScZqmEf0IBo_AGrgKA": "natural_gas_production_per_person",
  "pyj6tScZqmEfZd6DbNF1MKA": "natural_gas_proven_reserves_per_person",
  "pyj6tScZqmEc5qiv87tr3NA": "natural_gas_proved_reserves_total",
  "pyj6tScZqmEeUgUuvuJTONQ": "oil_production_per_person",
  "pyj6tScZqmEdNIa3ckVXaCQ": "oil_production_total",
  "pyj6tScZqmEfrI4YlDJDUag": "oil_proven_reserves_per_person",
  "pyj6tScZqmEfJfS1WYrLeBA": "oil_proved_reserves_total",
  "pyj6tScZqmEcm0fIa0IVtKw": "oil_consumption_total",
  "0ArfEDsV3bBwCdERNZmlfUGM5YVE3bmEwODdlRDFqSkE": "oil_consumption_per_cap",
  "rvRwTPi0n_94EScVA3YjLeg": "drought_deaths_annual_number",
  "rvbbs7uxQc7swJ4RR2BcQfA": "earthquake_deaths_annual_number",
  "rvtZF_JC0OI27tL66o6hiMQ": "epidemic_deaths_annual_number",
  "r4WUTsck3NfccM6UsKlGF7g": "extreme_temperature_deaths_annual_number",
  "rtESPUlrTyLEoHpURqE8RAg": "flood_deaths_annual_number",
  "r0JePjBgBQqtuh5wh1Wz9CA": "storm_deaths_annual_number",
  "rdBew79hTeXcIXhB1VCTPfg": "tsunami_deaths_annual_number",
  "rSv5aMDwESiKg-yA__-tRFg": "plane_crash_deaths_annual_number",
  "rAYA0bjnfYwzXnir0cigijQ": "drought_affected_annual_number",
  "rG_BjsDwyS2n7DANNH3i5vQ": "earthquake_affected_annual_number",
  "r6H8dfVPu2CJ2nzI8X0jurw": "epidemic_affected_annual_number",
  "ryPd-H6Kn3wcHC50zyImqUg": "extreme_temperature_affected_annual_number",
  "rsCDusOObseaoBUdarzw7Kw": "flood_affected_annual_number",
  "rAxnmm4ZL2HrYjIqJX0Ch-w": "storm_affected_annual_number",
  "rskN46tpbe6Iy3K_ULk1_cQ": "tsunami_affected_annual_number",
  "rhbS3kWOfMzvY4ofUFoeFJg": "plane_crash_affected_annual_number",
  "0AkBd6lyS3EmpdEVUcEJVRzlFWWRRcjhveGlrQzdwdUE": "co2_intensity_of_economic_output_kg_co2_per_2005_ppp_of_gdp",
  "pyj6tScZqmEed4UamoiNCFA": "cumulative_co2_emissions_tonnes",
  "phAwcNAVuyj0uBndTxZAXNQ": "sulfur_emissions_per_person_kg",
  "phAwcNAVuyj1NHPC9MyZ9SQ": "yearly_co2_emissions_1000_tonnes",
  "t9SYWh7siLJDzyZYN1R4HfQ": "total_sulfur_emission_kilotonnes",
  "pp59adS3CHWeB1N1HlpFQVQ": "forest_land_total_area_ha",
  "pp59adS3CHWeECA6Gf__BNQ": "primary_forest_area_ha",
  "pp59adS3CHWc4aJd9fV8zZg": "planted_forest_area_ha",
  "pp59adS3CHWe8O-N9RgxzDw": "wood_removal_cubic_meters",
  "pp59adS3CHWfRGgfhjf8FBQ": "forest_coverage_percent",
  "pp59adS3CHWcsSl830EklJA": "biomass_stock_in_forest_tons",
  "pp59adS3CHWdFemmS_iN5fw": "privately_owned_forest_land_percent",
  "pp59adS3CHWdtCylhQOQiXw": "privately_owned_other_wooded_land_percent",
  "pp59adS3CHWf66stZ2oNUAA": "forest_products_removal_total_dollar",
  "pp59adS3CHWd9CVdfFx1dEw": "forest_products_removal_per_ha_dollar",
  "0AkBd6lyS3EmpdEF3alRGS0JQZVgwSW1FWUxUQmZoWXc": "agricultural_land_percent_of_land_area",
  "0AkBd6lyS3EmpdFRuaV91Mm9JeUhwR1hHRXJhV3ZBQkE": "forest_area_sq_km",
  "0AkBd6lyS3EmpdFFWcWdEM0RXT1lRZ0wwRVNsakZCaWc": "surface_area_sq_km",
  "rPN9VekxwpUzwowMaxg9Ybw": "renewable_water_cu_meters_per_person",
  "riLRFECHMsTq7OTa2KYZCWA": "internal_renewable_water_cu_meters_per_person",
  "rezAT4nYhKc2Loe6CxWSPWw": "water_withdrawal_cu_meters_per_person",
  "ruUmTRBZ5xYjpOAhOT9VQbw": "municipal_water_withdrawal_cu_meters_per_person",
  "rab3jHe_JZrU1pqlX0xnQEw": "agricultural_water_withdrawal_percent_of_total",
  "rGKP-BBylLOM11iGahW1lxA": "industrial_water_withdrawal_percent_of_total",
  "r58mA3XNUvBov6M_1T_FiUg": "municipal_water_withdrawal_percent_of_total",
  "rt3BaikcRwJBNC0CvQsjDCA": "desalinated_water_produced_billion_cu_meters",
  "rIG3ZWxv381t2bIL2BNaIVw": "total_water_withdrawal_billion_cu_meters",
  "phAwcNAVuyj0NpF2PTov2Cw": "infant_mortality_rate_per_1000_births",
  "0ArfEDsV3bBwCdDdTQUtvNEJhb0RjRjU0WUtET1R0Vnc": "underweight_children",
  "tbpc_fYEqPzaE7pDWBG84Rw": "all_causes_deaths_in_newborn_per_1000_births",
  "t_0WcTuc94YT9cJVvy1tmUg": "birth_asphyxia_deaths_in_newborn_per_1000_births",
  "tneegXfZGHA0-nLG25ypnyg": "congenital_deaths_in_newborn_per_1000_births",
  "tX7NQAP_5H4TOS1OEnxdxYw": "diarrhoeal_deaths_in_newborn_per_1000_births",
  "tOXHWd6PcUGK3Dg-k2N8Clw": "pneumonia_deaths_in_newborn_per_1000_births",
  "tVMPnbOfGdvTRrtbuIbRw5w": "prematurity_deaths_in_newborn_per_1000_births",
  "tGVRSoAJtdwQ30CqCSexKJA": "sepsis_deaths_in_newborn_per_1000_births",
  "t1E7e32tlIxtJU9UhnR9nJg": "tetanus_deaths_in_newborn_per_1000_births",
  "tiYIen5OQP0fKiQbg6g8VyA": "other_deaths_in_newborn_per_1000_births",
  "tBVcAKWEbc2s687q6D9yuYg": "all_causes_deaths_in_newborn_total_deaths",
  "twrll6eL8GeIU4P71aTakmg": "birth_asphyxia_deaths_in_newborn_total_deaths",
  "tUvFsKz2lGL-GtPmw47VLdg": "congenital_deaths_in_newborn_total_deaths",
  "tKZcnGP_XImMXRZ4tZqtjMg": "diarrhoeal_deaths_in_newborn_total_deaths",
  "tjvbVGhVx7YCk1uguLrkaag": "pneumonia_deaths_in_newborn_total_deaths",
  "tVyFL3CyRTuGEnRQErDeSLQ": "prematurity_deaths_in_newborn_total_deaths",
  "tRA1VmW2ZQ7sCsoD7AHIilg": "sepsis_deaths_in_newborn_total_deaths",
  "tB6Gkh4rLC9yB2TXfHSApIA": "tetanus_deaths_in_newborn_total_deaths",
  "tyPO4OLCIZtK5zcdkafcHMA": "other_deaths_in_newborn_total_deaths",
  "t9FP3_OPrE2ug_I_iNsbePg": "all_causes_deaths_in_children_1_59_months_per_1000_births",
  "tADSStUzP0ADEPYqsIfVHMQ": "diarrhoeal_deaths_in_children_1_59_months_per_1000_births",
  "t4C4M_ynK9Ho8tGRj6a5U5w": "hiv_deaths_in_children_1_59_months_per_1000_births",
  "tfOi0Ji7pJDJbxJVqwJXj9g": "injury_deaths_in_children_1_59_months_per_1000_births",
  "tunqKEwokfnJMDA1g7W8KwA": "malaria_deaths_in_children_1_59_months_per_1000_births",
  "tfyKUbGu10P_WOgHSOHhCJg": "measles_deaths_in_children_1_59_months_per_1000_births",
  "tZhYsw0sliDInwowT0iWTLQ": "meningitis_deaths_in_children_1_59_months_per_1000_births",
  "tqAEpy6pMtN7ULlF6uIzEog": "ncd_deaths_in_children_1_59_months_per_1000_births",
  "t4RbfeK6Dtt2srxw4REoXxQ": "pertussis_deaths_in_children_1_59_months_per_1000_births",
  "tCe8N5KXAYCJteQokAqVM_A": "pneumonia_deaths_in_children_1_59_months_per_1000_births",
  "tVAqgYwCDZfcq9jSCI87SAw": "other_infections_deaths_in_children_1_59_months_per_1000_birt",
  "taYeez4Mkk8OvdH-q4QAxxQ": "all_causes_deaths_in_children_1_59_months_total_deaths",
  "tDzIq4iIDwNtYthN_QYZwZg": "diarrhoeal_deaths_in_children_1_59_months_total_deaths",
  "tQe6yinBBauXLvBFroZEL3Q": "hiv_deaths_in_children_1_59_months_total_deaths",
  "tnRIpcH0InZUFz7f2ziXKog": "injury_deaths_in_children_1_59_months_total_deaths",
  "t1Vf-4rkGlG20pYzuGib3hw": "malaria_deaths_in_children_1_59_months_total_deaths",
  "tYZAce4wGXEp5Jve3AI7yWQ": "measles_deaths_in_children_1_59_months_total_deaths",
  "tX-vHLzEZ7mqfk1vf6SbUXA": "meningitis_deaths_in_children_1_59_months_total_deaths",
  "tfmFGk3PIvTgimegQiHOtSQ": "ncd_deaths_in_children_1_59_months_total_deaths",
  "tdOlzqGxVdDnnCpfWyyGX1A": "pertussis_deaths_in_children_1_59_months_total_deaths",
  "tcYkTk6KMHsrXzcM9WyUxbw": "pneumonia_deaths_in_children_1_59_months_total_deaths",
  "tiehFos6jB-lekEAH028deA": "other_infections_deaths_in_children_1_59_months_total_deaths",
  "phAwcNAVuyj0Zbn8wVYsEHQ": "breast_cancer_new_cases_per_100000_women",
  "phAwcNAVuyj3XpKHFksEPcA": "cervical_cancer_new_cases_per_100000_women",
  "phAwcNAVuyj2P7lqZXLeZAw": "colonandrectum_cancer_new_cases_per_100000_women",
  "phAwcNAVuyj2xhaKENmyRKw": "liver_cancer_new_cases_per_100000_women",
  "phAwcNAVuyj0UaQ2DNjK9Lg": "lung_cancer_new_cases_per_100000_women",
  "phAwcNAVuyj0je8zzeM4WXQ": "stomach_cancer_new_cases_per_100000_women",
  "phAwcNAVuyj2fGuJ1VdTpOw": "breast_cancer_number_of_new_female_cases",
  "phAwcNAVuyj0g-SgNTI23GQ": "cervical_cancer_number_of_new_female_cases",
  "phAwcNAVuyj2L5YHqVxRTLA": "colonandrectum_cancer_number_of_new_female_cases",
  "phAwcNAVuyj1_IYQtrqQCKQ": "liver_cancer_number_of_new_female_cases",
  "phAwcNAVuyj2oQAW8_53cVQ": "lung_cancer_number_of_new_female_cases",
  "phAwcNAVuyj1aXfw3aV83TA": "stomach_cancer_number_of_new_female_cases",
  "phAwcNAVuyj3wJUwXXDdiGg": "breast_cancer_deaths_per_100000_women",
  "phAwcNAVuyj0jPl21g3mqfQ": "cervical_cancer_deaths_per_100000_women",
  "phAwcNAVuyj3bdzmKAvdUSw": "colonandrectum_cancer_deaths_per_100000_women",
  "phAwcNAVuyj2ItBsVpK9VBA": "liver_cancer_deaths_per_100000_women",
  "phAwcNAVuyj2hZorcv6aVLA": "lung_cancer_deaths_per_100000_women",
  "phAwcNAVuyj0RpUEQPgGcZQ": "stomach_cancer_deaths_per_100000_women",
  "phAwcNAVuyj07QB-Apx8RfQ": "breast_cancer_number_of_female_deaths",
  "phAwcNAVuyj2KBU_veE9AQg": "cervical_cancer_number_of_female_deaths",
  "phAwcNAVuyj0ndPkFlozzXQ": "colonandrectum_cancer_number_of_female_deaths",
  "phAwcNAVuyj2LwNOwMSnJQA": "liver_cancer_number_of_female_deaths",
  "phAwcNAVuyj0RMyUI1QFfaA": "lung_cancer_number_of_female_deaths",
  "phAwcNAVuyj1o1rJNFHpQZw": "stomach_cancer_number_of_female_deaths",
  "phAwcNAVuyj2UBFFaFy3Ebg": "colonandrectum_cancer_new_cases_per_100000_men",
  "phAwcNAVuyj1u0KpZbsopCA": "liver_cancer_new_cases_per_100000_men",
  "phAwcNAVuyj1kCRbsnNcTVg": "lung_cancer_new_cases_per_100000_men",
  "phAwcNAVuyj3qX39HWaQjEg": "prostate_cancer_new_cases_per_100000_men",
  "phAwcNAVuyj1XKvT6zwrMPw": "stomach_cancer_new_cases_per_100000_men",
  "phAwcNAVuyj3nBOvEwrkMTA": "colonandrectum_cancer_number_of_new_male_cases",
  "phAwcNAVuyj2LIYJXVW9EVw": "liver_cancer_number_of_new_male_cases",
  "phAwcNAVuyj0sd0z2MGpeeA": "lung_cancer_number_of_new_male_cases",
  "phAwcNAVuyj2vXUZJKI0XHA": "prostate_cancer_number_of_new_male_cases",
  "phAwcNAVuyj3XF3cD2lbecA": "stomach_cancer_number_of_new_male_cases",
  "phAwcNAVuyj0VhkB8-rRHQg": "colonandrectum_cancer_deaths_per_100000_men",
  "phAwcNAVuyj3rojF8TmZtOw": "liver_cancer_deaths_per_100000_men",
  "phAwcNAVuyj2_ibAjsuNgYA": "lung_cancer_deaths_per_100000_men",
  "phAwcNAVuyj2S9phBhTP3dw": "prostate_cancer_deaths_per_100000_men",
  "phAwcNAVuyj3ky4_oAkatBw": "stomach_cancer_deaths_per_100000_men",
  "phAwcNAVuyj0-sqkfnD4rGA": "colonandrectum_cancer_number_of_male_deaths",
  "phAwcNAVuyj1RD88c3w1vNg": "liver_cancer_number_of_male_deaths",
  "phAwcNAVuyj2Az43qu-dQJQ": "lung_cancer_number_of_male_deaths",
  "phAwcNAVuyj1ImYURLRHPRA": "prostate_cancer_number_of_male_deaths",
  "phAwcNAVuyj2NmCvOcsjpag": "stomach_cancer_number_of_male_deaths",
  "phAwcNAVuyj3XYThRy0yJMA": "total_health_spending_percent_of_gdp",
  "pyj6tScZqmEcJI3KBJnrlDQ": "government_share_of_total_health_spending_percent",
  "pyj6tScZqmEcXBFxQw8cFaw": "private_share_of_total_health_spending_percent",
  "tXf6_OUYVmyEMZo0g4DQw6w": "out_of_pocket_share_of_total_health_spending_percent",
  "pyj6tScZqmEd7K-YgYOkGFQ": "government_health_spending_of_total_gov_spending_percent",
  "tR3MM-UTZ0B44BKxxWeAZaQ": "total_health_spending_per_person_international_dollar",
  "tZ3uHUdw0H__Siyj78GXsGg": "government_health_spending_per_person_international_dollar",
  "pyj6tScZqmEeL79qOoKtofQ": "total_health_spending_per_person_us",
  "tBwBBkViOJoycBhLnWHqwSQ": "government_health_spending_per_person_us",
  "phAwcNAVuyj2yo1IzJQmbZg": "medical_doctors_per_1000_people",
  "pyj6tScZqmEe1GaiYJX2qGA": "people_living_with_hiv_number_all_ages",
  "pyj6tScZqmEfbZyl0qjbiRQ": "adults_with_hiv_percent_age_15_49",
  "0ArfEDsV3bBwCdFk2WGhwakxTQkt4NUtTdFJDSlFHQ3c": "newly_hiv_infected_number_all_ages",
  "0ArfEDsV3bBwCdDREUkRSRDJtQmFNTE1TYmRYX1pFNEE": "newly_hiv_infected_percent_age_15_49",
  "0ArfEDsV3bBwCdHZJdFBhYVlvck43d1R6ZFYzUWpiLWc": "annual_hiv_deaths_number_all_ages",
  "0ArfEDsV3bBwCdHMzRDA5Z1RjWkJIWkNfdWNBVFR6b1E": "art_coverage_percent_cd4_l_350",
  "pp59adS3CHWcGnFB9pe14OA": "malaria_cases_per_100000_reported",
  "pp59adS3CHWfGZUVJ2L-dCw": "malaria_deaths_per_100000_reported",
  "pp59adS3CHWczfPHQMiqxCg": "malaria_number_of_cases_reported",
  "pp59adS3CHWfZGL9qouvTbQ": "malaria_number_of_deaths_reported",
  "0AkBd6lyS3EmpdF9OQ2dSSG5nNFhpS3RnRVZHUzZMb3c": "births_attended_by_skilled_health_staff_percent_of_total",
  "0AkBd6lyS3EmpdFp2OENYMUVKWnY1dkJLRXAtYnI3UVE": "contraceptive_use_percent_of_women_ages_15_49",
  "pyj6tScZqmEcVezxiMlWaRw": "maternal_mortality_ratio_per_100000_live_births",
  "tOJs331rbt36sNBXE8g5AUg": "maternal_deaths_total_number",
  "t2ha4jg1M70Le8CH3wHcPIQ": "maternal_deaths_lifetime_risk_per_1000",
  "tgJHpDEY4S7hxJpELGJueWA": "stillbirths_per_1000_births",
  "troMumuI0Y6Phpwnj6qXa_A": "suicide_per_100000_people",
  "0AkBd6lyS3EmpdGhpWDY5QVlOdUxpVGhaMVlUOE9iX0E": "malnutrition_weight_for_age_percent_of_children_under_5",
  "phAwcNAVuyj2sdmdhX9zuKg": "sugar_per_person_g_per_day",
  "0ArfEDsV3bBwCdGlYVVpXX20tbU13STZyVG0yNkRrZnc": "food_supply_kilocalories_per_person_and_day",
  "0AgogXXPMARyldGJqTDRfNHBWODJMRWlZaVhNclhNZXc": "alcohol_consumption_per_adult_15plus_litres",
  "tRccVp7QMaCXMv19CcxERaA": "smoking_adults_percent_of_population_over_age_15",
  "thortPEzDn2xc_5bU255mPA": "smoking_women_percent_of_women_over_age_15",
  "t60tpjxpWq3Bm-nBOvSm3og": "smoking_men_percent_of_men_over_age_15",
  "0ArfEDsV3bBwCdF9saE1pWUNYVkVsNU1FdW1Yem81Nmc": "body_mass_index_bmi_men_kgperm2",
  "0ArfEDsV3bBwCdGt0elo2dzJMVVQ3WmFGSmdhc09LRlE": "body_mass_index_bmi_women_kgperm2",
  "0ArfEDsV3bBwCdHNwRm9DN1FnT3hXWWZVSncxMkZyS2c": "blood_pressure_sbp_men_mmhg",
  "0ArfEDsV3bBwCdHBzUVVSMDlTX1ZCUnNJQ3ZFdkFXVFE": "blood_pressure_sbp_women_mmhg",
  "0ArfEDsV3bBwCdDU5SnRoQ0xlZWhwRUZ6RFNQV042enc": "cholesterol_fat_in_blood_men_mmolperl",
  "0ArfEDsV3bBwCdGJHcHZkSUdBcU56aS1OT3lLeU4tRHc": "cholesterol_fat_in_blood_women_mmolperl",
  "rVyfxaPK4dJ9B6ZdgG34F-g": "infectious_tb_new_cases_per_100000_estimated",
  "r0pD5wznwEUJ0ipdxAWQjiA": "infectious_tb_new_cases_per_100000_reported",
  "rOPfJcbTTIyS-vxDWbkfNLA": "infectious_tb_number_of_new_cases_estimated",
  "rcbx0R-TXbkqRCyvKzn08fg": "infectious_tb_number_of_new_cases_reported",
  "rDb6EYc4YUTBRfXlBvjHYlg": "infectious_tb_detection_rate_percent",
  "rjGHot8B6YSt3kPYEG8nANA": "infectious_tb_detection_rate_percent_dots_only",
  "rewICFMTvBuer8UoJIK0yUg": "infectious_tb_treatment_dots_completed_percent",
  "rKfjGaPxqirPDe8gnTVKuIw": "tb_programme_dots_population_coverage_percent",
  "rMsQHawTObBb6_U2ESjKXYw": "all_forms_of_tb_new_cases_per_100000_estimated",
  "rZNoyaocUmUGuFyRgjJUpig": "all_forms_of_tb_existing_cases_per_100000_estimated",
  "rWM9yEzjpGJvcJlUAIm35tA": "all_forms_of_tb_deaths_per_100000_estimated",
  "rYICvtvVz28fVyQuG_ote2w": "all_forms_of_tb_new_cases_per_100000_reported",
  "rhZayTbvH3ZLlSN64OuRYFg": "all_forms_of_tb_number_of_new_cases_estimated",
  "rOCGMcGcrZs-dfeTEC792ZQ": "all_forms_of_tb_number_of_existing_cases_estimated",
  "rrQ_y5fqQPlznp5mJGXWr-A": "all_forms_of_tb_number_of_deaths_estimated",
  "r5UikGjnZlemlelKY0NX9Pg": "all_forms_of_tb_number_of_new_cases_reported",
  "ru195-zJ0rsx5axPIvm_bRA": "all_forms_of_tb_detection_rate_percent",
  "rutVwqgB14uRV_f2dRbqhUA": "all_forms_of_tb_detection_rate_percent_dots_only",
  "rRCxDI3hB9E9zvc8qSe11qg": "tb_with_hivplus_new_cases_per_100000_estimated",
  "rQV47xgPGa3qOPHoLiVon-w": "tb_with_hivplus_existing_cases_per_100000_estimated",
  "rUBCConMMLm9CxPXUGm325A": "tb_with_hivplus_deaths_per_100000_estimated",
  "rERPF4iYruK0DhAw_0tb5nA": "tb_with_hivplus_number_of_new_cases_estimated",
  "reiGJwoabnMOrPeFima_9ng": "tb_with_hivplus_number_of_existing_cases_estimated",
  "rFAkC0Ae7oXxrVqosJ4NWUA": "tb_with_hivplus_number_of_deaths_estimated",
  "phAwcNAVuyj3Os9LVO_pRDA": "bad_teeth_per_child_12_yr",
  "txVTyScWObTBNuMmkNtLh1w": "dtp3_immunized_percent_of_one_year_olds",
  "t7pU8fR9_ZzRFIMF3FX47YQ": "hepb3_immunized_percent_of_one_year_olds",
  "thClNiXoQqfJDzTv0SYIHZg": "hib3_immunized_percent_of_one_year_olds",
  "pyj6tScZqmEenS18Yjl_SOQ": "mcv_immunized_percent_of_one_year_olds",
  "tnvxVX8aOAl0dwDNujbELPQ": "pab_immunized_percent_of_newborns",
  "0AkBd6lyS3EmpdEpMTHBoMmNzcDVCNVRHWE5zSVJVRHc": "broadband_subscribers",
  "0AkBd6lyS3EmpdHdmMGVNNnV1SHBONDRTdTJzTVBKQXc": "broadband_subscribers_per_100_people",
  "0AkBd6lyS3EmpdEhWLWtqNzljbWg4ZXV6M09JQXNGaUE": "cell_phones_total",
  "0AkBd6lyS3EmpdG1MSjEyS0h2QjRQZ3FXRVR2dVQyeFE": "cell_phones_per_100_people",
  "pyj6tScZqmEcfLoOcU6GAfg": "fixed_line_and_mobile_phone_subscribers_per_100_people",
  "0AkBd6lyS3EmpdC1PcWJUZldDelFyQXdaOEtDUG9HSUE": "internet_users_total_number",
  "0AkBd6lyS3EmpdGwzSGV5OE9FOGhURlhTdEQtMW1TNkE": "internet_users_per_100_people",
  "pyj6tScZqmEfUXdC83YSzfw": "personal_computers_total",
  "pyj6tScZqmEdFW4nUY4gQdA": "personal_computers_per_100_people",
  "0ArfEDsV3bBwCdE4tekJPYkR4WmJqYTRPWjc3OTl4WUE": "improved_sanitation_overall_access_percent",
  "pyj6tScZqmEfLbPu48DrKfQ": "improved_sanitation_urban_access_percent",
  "0ArfEDsV3bBwCdFNPMTE3d3FHTHdYaGFMXzJyNDBGd3c": "improved_sanitation_rural_access_percent",
  "0AkBd6lyS3EmpdDBKd2V5VmxkYlJuUHAtOURzUkZzNEE": "roads_paved_percent_of_total_roads",
  "pyj6tScZqmEd98lRwrU3gIg": "improved_water_source_overall_access_percent",
  "0ArfEDsV3bBwCdDlJNzNjcVc5Sm9memNuVHRzY1FsOXc": "improved_water_source_urban_access_percent",
  "0ArfEDsV3bBwCdFhhVzhXUEh0U0hlQ3M3TTZIQTFySUE": "improved_water_source_rural_access_percent",
  "rsOONWhmGBtzb4j__0MJv7Q": "population_aged_0_4_years_both_sexes_percent",
  "rC5UskPU6PRVlmN7eXoridw": "population_aged_5_9_years_both_sexes_percent",
  "rmViJSkPd4xZneV2Q6gzFwQ": "population_aged_10_14_years_both_sexes_percent",
  "r4VUu4a4AaWqXXoAsFz-z-Q": "population_aged_15_19_years_both_sexes_percent",
  "rTU20DXn0Bi7bTwW5T6J3gg": "population_aged_20_39_years_both_sexes_percent",
  "rLwpdKbW2OykBbvVxhYKrhA": "population_aged_40_59_years_both_sexes_percent",
  "rH6TEe8f_WNq_8x9pWZ3W0A": "population_aged_60plus_years_both_sexes_percent",
  "rovrK0Uj9JPN95P9adob0tw": "population_aged_0_4_years_total_number",
  "r83X3yfjC6ENYWoo41yDehg": "population_aged_5_9_years_total_number",
  "r9ztOSMb5WNHUBLwlgJqPSw": "population_aged_10_14_years_total_number",
  "rFmJvuotJYE30q4nWEvpGJA": "population_aged_15_19_years_total_number",
  "rHrin819tHgZudARnpsN0Mg": "population_aged_20_39_years_total_number",
  "ri9SXMNc7TpebHucmAYepGQ": "population_aged_40_59_years_total_number",
  "rVD6A2uAmeIE0BQNW1KSg3A": "population_aged_60plus_years_total_number",
  "roLpNPQkooNCFzpTWXQ48Dw": "population_aged_0_4_years_female_percent",
  "re3efChATTYAT5bRqoaChXA": "population_aged_5_9_years_female_percent",
  "rJwVmwTFzheqVUzYWwSqXlA": "population_aged_10_14_years_female_percent",
  "rYEHWlJHaLjHtcsSpRRJeig": "population_aged_15_19_years_female_percent",
  "rWpQHQIQdntj6BEK8OIuWYw": "population_aged_20_39_years_female_percent",
  "rElErbmOnSM6om03a1uinKQ": "population_aged_40_59_years_female_percent",
  "rjhBvpeRgCxBq0EnQVN6b0w": "population_aged_60plus_years_female_percent",
  "rIpDsoI9lVTCh_PRqm66Tcw": "population_aged_0_4_years_male_percent",
  "rgyZrNmSfXcPJQxJK7IxnEw": "population_aged_5_9_years_male_percent",
  "rmQZ_H88rIhF3315QBZpcIQ": "population_aged_10_14_years_male_percent",
  "rYfw4UJZSizLRtgDs73d5jA": "population_aged_15_19_years_male_percent",
  "rab1GmqpzJkWd4MF0hieVgA": "population_aged_20_39_years_male_percent",
  "rcQkob1yAm-to1scz51flgw": "population_aged_40_59_years_male_percent",
  "rSkhGgUVN74knEhSAhBSSKA": "population_aged_60plus_years_male_percent",
  "0AkBd6lyS3EmpdFY5Z0QzTzRRbzJ1VXdqdGVyNE0tcFE": "population_growth_annual_percent",
  "tUSeGJOQhafugwUvHvY-wLA": "crude_birth_rate_births_per_1000_population",
  "tHyj-2jRvK3CCNJOc5Vm-HQ": "crude_death_rate_deaths_per_1000_population",
  "pyj6tScZqmEdIphYUHxcdLg": "teen_fertility_rate_births_per_1000_women_ages_15_19",
  "0ArfEDsV3bBwCdERQeFplM2VWczVrMTFfMXVrQkJpVXc": "new_births_total_number_estimated",
  "tAQ31_cAELrHqNc2qa13uHw": "sex_ratio_all_age_groups",
  "tfWSVJPJHn3u7e_7MUaCbnw": "sex_ratio_0_14_years",
  "ta-Da73B_Z7lKOZo8o-Ykvw": "sex_ratio_15_24_years",
  "tF_P_4G0g5bR3lYmQT9Tv4w": "sex_ratio_15_49_years",
  "tQP1KnoWcjjtz3wmq0bnGNA": "sex_ratio_above_50_years",
  "pyj6tScZqmEdwXv1tqzV4Xg": "population_in_urban_agglomerations_m_1_million_percent_of_total",
  "pyj6tScZqmEfH89V6UQhpZA": "urban_population",
  "phAwcNAVuyj0-LE4StzCsEw": "urban_population_percent_of_total",
  "pyj6tScZqmEcRJEN8MyV3PQ": "urban_population_growth_annual_percent",
  "phAwcNAVuyj2biT80zgTsYQ": "children_and_elderly_per_100_adults",
  "tH113JLeGr5DhWgtqN2FxWg": "median_age_years",
  "tVY51lNaCL9m9xPqf29oFAA": "population_density_per_square_km",
  "phAwcNAVuyj0XOoBL_n5tAQ": "population_total",
  "t4eF8H_jq_xyKCUHAX6VT1g": "age_at_1st_marriage_women",
  "tZgPgT_sx3VdAuyDxEzenYA": "murder_per_100000_people",
  "tKOphM3UPRd94T6C6pmsuXw": "corruption_perception_index_cpi",
  "0ArfEDsV3bBwCdGQ2YlhDSWVIdXdpMmhLY2ZZRHdNNnc": "democracy_score_use_as_color",
  "tyadrylIpQ1K_iHP407374Q": "hdi_human_development_index",
  "rzFD5mOuB5mR7-vLoP04LAQ": "agriculture_workers_percent_of_labour_force",
  "rqcJTExcUqNdolB-7flqebQ": "industry_workers_percent_of_labour_force",
  "r4orIwujZpT-z3Exd_9ARpQ": "service_workers_percent_of_labour_force",
  "rraOr_PTB0jcQ60TagEH_WQ": "female_agriculture_workers_percent_of_female_labour_force",
  "rA5BvUGX_Es43DaKb3FidUg": "female_industry_workers_percent_of_female_labour_force",
  "r1B3mjfpBItUmvrhqaRgTWQ": "female_service_workers_percent_of_female_labour_force",
  "rtt_ihBgyYafmDJpThQecoA": "male_agriculture_workers_percent_of_male_labour_force",
  "rmLnlLnnm2kjBbNsBZYxqow": "male_industry_workers_percent_of_male_labour_force",
  "ravxsZdBslM5zF5HwDsX30g": "male_service_workers_percent_of_male_labour_force",
  "rW7k_DdDKlGgJhzYRuNvguw": "family_workers_percent_of_labour_force",
  "rcO6CXqmEjV-wS-29qejCpw": "salaried_workers_percent_of_labour_force",
  "rSrvaPPzWvOyTMb9_dfJDtQ": "self_employed_percent_of_labour_force",
  "rjFKVVoWIVbTgdtJK2xOZqQ": "female_family_workers_percent_of_female_labour_force",
  "rhuyv42EAyApMwy4tDYm3XQ": "female_salaried_workers_percent_of_female_labour_force",
  "rIe2Y4f4Ehde4I4BGPN2VBg": "female_self_employed_percent_of_female_labour_force",
  "rJMlhr2YOvL2EE5NhpbfYAg": "male_family_workers_percent_of_male_labour_force",
  "riwXFQrsUhb96BT2yFC9rFw": "male_salaried_workers_percent_of_male_labour_force",
  "raAOA9AFRPzq5ilAm5Qa65Q": "male_self_employed_percent_of_male_labour_force",
  "rfHz_nx27dDQo4dUoIeVT3A": "aged_15_24_employment_rate_percent",
  "rV0ksExNqh6V_h40f0_nFjg": "aged_15plus_employment_rate_percent",
  "rRS0FbArN8jYsY25X-ZiU9A": "females_aged_15_24_employment_rate_percent",
  "rOXvRa2ZC2oXqBn7gz62IMg": "females_aged_15plus_employment_rate_percent",
  "rCyfwvThkbHVlNVw48vHybg": "males_aged_15_24_employment_rate_percent",
  "rTRt7Z5m9i9D9-vvipvdx2w": "males_aged_15plus_employment_rate_percent",
  "rx1TECfEnGlnomonxCCO-Aw": "aged_15_64_labour_force_participation_rate_percent",
  "rTrB-PY0sfM_gdAQ20XovfA": "aged_25_54_labour_force_participation_rate_percent",
  "ryyQX_1TXlohXWOSUswhIKg": "aged_15plus_labour_force_participation_rate_percent",
  "r1hlZB_n1rpXTij11Kw7lTQ": "aged_65plus_labour_force_participation_rate_percent",
  "rLRScmH2JZmjxsCGW2LB1cA": "females_aged_15_64_labour_force_participation_rate_percent",
  "rgdYcit5cC0wxcLAQf9kJ_Q": "females_aged_25_54_labour_force_participation_rate_percent",
  "rZyHDNFsPBn7cqZCIzDQtIg": "females_aged_15plus_labour_force_participation_rate_percent",
  "rEZ0xOSmU7UuX7iOyL0Xp3g": "females_aged_65plus_labour_force_participation_rate_percent",
  "rB4P-M5oVWuv9CyQ5s1mvOA": "males_aged_15_64_labour_force_participation_rate_percent",
  "rj102tw9R1O_d56Uw_eqBzg": "males_aged_25_54_labour_force_participation_rate_percent",
  "rImcpLhokI0fXWNA-2nSWFw": "males_aged_15plus_labour_force_participation_rate_percent",
  "r_hbrX2qsjHphzAAlwsxhRA": "males_aged_65plus_labour_force_participation_rate_percent",
  "rb0oP4d1BREXa8xMIUf4NZg": "aged_15_24_unemployment_rate_percent",
  "rEMA-cbNPaOtpDyxTcwugnw": "aged_25_54_unemployment_rate_percent",
  "rlD36wGmkwFt3ED558waCTQ": "aged_15plus_unemployment_rate_percent",
  "rNn0y3e0bCpaqTM_8BVZBdg": "aged_55plus_unemployment_rate_percent",
  "rCRqVXC95LeKm_EvLrFNXKw": "long_term_unemployment_rate_percent",
  "rMf--YMvuEKf2LVppT63Xvw": "females_aged_15_24_unemployment_rate_percent",
  "r9StWVETzyX9Lv-r4-2sh6w": "females_aged_25_54_unemployment_rate_percent",
  "rcHjAQAzF2e1yR1R-hywCEw": "females_aged_15plus_unemployment_rate_percent",
  "rz8kJ7CIyckuQAWgHUHe4sA": "females_aged_55plus_unemployment_rate_percent",
  "rT5EpK40a19zVodp1HV1xGw": "female_long_term_unemployment_rate_percent",
  "rGS7_GpdXrYyjhKkAFcLHGA": "males_aged_15_24_unemployment_rate_percent",
  "rjkDFSPV2pw9Pbnz2kpiqPQ": "males_aged_25_54_unemployment_rate_percent",
  "r5_68IYi0bC1bRjGMFYFk8g": "males_aged_15plus_unemployment_rate_percent",
  "rSMPg9BmVRsqE8_k1ARUudA": "males_aged_55plus_unemployment_rate_percent",
  "rezDaYxOOBEFgR4TiPN9qtw": "male_long_term_unemployment_rate_percent",
  "p8SIY47PNEw6pJRPAS1tXPQ": "under_five_mortality_from_cme_per_1000_born",
  "p8SIY47PNEw4TgTkrmIVIXA": "under_five_mortality_from_ihme_per_1000_born",
  "phAwcNAVuyj0npaJxInyYDg": "old_version_of_income_per_person_version_3",
  "0ArfEDsV3bBwCdE5xWmcyYVZJQzJvOFpZUklqX3lkSkE": "old_version_of_income_per_person_version_8",
  "tSUr_yZVbM6a3AGJEq_Z2Pw": "alternative_gdppercapita_ppp_inflation_adjusted_from_pwt",
  "0ArfEDsV3bBwCdGlGLVd4OGVfcVdScVBSS0JLVHpiMlE": "subsistence_incomes_per_person",
  "pyj6tScZqmEd1R9UmohEIaA": "alternative_poverty_percent_below_nationally_defined_poverty",
  "phAwcNAVuyj0atjJIuxy-KQ": "data_quality_income_per_person",
  "p8SIY47PNEw6vGCNzlYJ5eA": "data_quality_life_expectancy",
  "phAwcNAVuyj1ONZlWMf9KQA": "data_quality_population",
  "p8SIY47PNEw4vx1GOsJM7bA": "estimate_or_projection_of_under_five_mortality_rate_from_ihme",
  "thlR4hyNMEnaVyV_uxRzjfQ": "data_quality_children_per_woman",
  "thDxsgSWvLGW4M1qUBby7OQ": "data_method_maternal_mortality",
  "tkdAnkbHJxPAlRX6P1mAh8w": "economic_growth_over_the_past_10_years",
  "rAIffGKCmiCdzTl1C0AR2nw": "how_far_to_the_north",
  "rX3Jfop_ebuY-chuMpCgmRg": "income_per_person_with_projections",
  "tiAiXcrneZrUnnJ9dBU-PAw": "life_expectancy_at_birth_with_projections",
  "tGdhNYGTGtunwzkKJ9aRhsA": "children_per_woman_total_fertility_with_projections",
  "tL0jLxFBF9TbXIN_39b1qcQ": "total_population_with_projections",
  "t6nZVbvqsF-BbyheqUxerZQ": "female_population_with_projections",
  "tZMsbTkrY0k4OkkkXEfp6pA": "male_population_with_projections",
  "rZrHzR__kZfmw6L_xUx7cwg": "population_growth_annual_percent_with_projections",
  "phAwcNAVuyj2t4ep52YXjSg": "year_categorization_1820_2010",
  "phAwcNAVuyj02SA7cGjnRbA": "year_categorization_1950",
  "tK87SOy-oZlfW99UDD7L3hw": "traffic_deaths_per_100000_people",
  "0AgogXXPMARyldGloTm9wekpDcUdMUjhlYWFKaHdnVWc": "burns_deaths_per_100000_people",
  "0AgogXXPMARyldF9ZRnhuWWljUXJHRDRpb0cyaHNLSUE": "drownings_per_100000_people",
  "0AgogXXPMARyldEJZajRXMWZPTE9nUXFBNUdPcG5yT2c": "falls_deaths_per_100000_people",
  "0AgogXXPMARyldGhVLXVsSTB0aDY2eXdLaEt1T0psdXc": "poisonings_deaths_per_100000_people",
  "tu0H0unnUriNvMXwH_qOqzw": "cars_trucks_and_buses_per_1000_persons",
  "t4pYrpNzP-JeR7zSjOyDofQ": "traffic_deaths_women_per_100000_people",
  "tUaaG6Pu9zT_BVIsLvGLQdA": "traffic_deaths_men_per_100000_people",
  "tUD6kmYmB_Bp85SRdEn1Krg": "suicide_women_per_100000_people",
  "tB8ge4cxd8TL7yIV4ALm5NA": "suicide_men_per_100000_people",
  "tyeSLo9Zpmw_e05IR3EoReg": "murdered_women_per_100000_people",
  "tHgVOu-6TYQ6Kig0Ur3Y-kw": "murdered_men_per_100000_people",
  "tLf-4GD5z0QxqsDoUz4vOlg": "car_deaths_per_100000_people",
  "t7-m0musxnWbugcQ9ECH4KA": "motorcycle_deaths_per_100000_people",
  "0AgogXXPMARyldC1rcTI5OU50Mnc1djdkNXpnWUFrZmc": "murdered_children_0_14_per_100000_people",
  "0AgogXXPMARyldDFGSGhtOWt0cS1JbEIzS29EZzlQRXc": "murdered_15_29_per_100000_people",
  "0AgogXXPMARyldG51d0o2T0JQWXFTMUlydWFsSTZMeFE": "murdered_30_44_per_100000_people",
  "0AgogXXPMARyldElCSWl6TkpaZ1JpcXVxa2tmUGhxbFE": "murdered_45_59_per_100000_people",
  "0AgogXXPMARyldGF1WHhpZFVQTWswclJHdjE3MkZ4c3c": "murdered_60plus_per_100000_people",
  "0AgogXXPMARyldGhJdkhTSHNEYTFKQjRrMlBwZXk1TkE": "suicide_age_0_14_per_100000_people",
  "0AgogXXPMARyldHNWNkNVR2Zwalc2U04zTjE5MDZlUkE": "suicide_age_15_29_per_100000_people",
  "0AgogXXPMARyldG9MeHpzRkNHQmZ4MmtxSnd2Y0o2UFE": "suicide_age_30_44_per_100000_people",
  "0AgogXXPMARyldGh2OWd2eVJiUnhScW9tOEtNTFkyQUE": "suicide_age_45_59_per_100000_people",
  "0AgogXXPMARyldEVRNFBZS2wzRmtZOWZEZDVZVG05dHc": "suicide_age_60plus_per_100000_people",
  "0AgogXXPMARyldHB2MkhmVDcyMG1Oa3Y5eEhRQ0VlUWc": "traffic_mortality_children_0_14_per_100000_people",
  "0AgogXXPMARyldEVPSG5qYzBfS0llQ1RnTl9wWXZodkE": "traffic_mortality_15_29_per_100000_people",
  "0AgogXXPMARyldGRqbENsQm5VMWFLdnRXV0w1S0tVSEE": "traffic_mortality_30_44_per_100000_people",
  "0AgogXXPMARyldFJrcW9wdlJITlBDYU9IbnRKdVllVGc": "traffic_mortality_45_59_per_100000_people",
  "0AgogXXPMARyldEw5RXhuckZuU1V2aVAzNDFZaDUxa2c": "traffic_mortality_60plus_per_100000_people",
  "0ArfEDsV3bBwCdHFkZlc5WkhVQmVmeU0tR0RsSUdTU0E": "ifpri_underweight_children",
  "0ArfEDsV3bBwCdE5UaXVKa2hWbUFzcUJJbVdHOE1VcFE": "maternal_mortality_ratio_who",
  "0AgogXXPMARyldElMWEl4RFVTemlMbzJqRU50ZDJ3SHc": "battle_deaths_per_100_000",
  "0Asm_G8nr4TCSdG1nNjk5RzItcUp6N2dSdHUwOENXa0E": "armed_forces_personnel_total",
  "0Asm_G8nr4TCSdFFoVWRjcUdKZDFydGdGNXkzS2ZRbHc": "armed_forces_personnel_percent_of_labor_force",
  "tIgjxaAg3M6kuHRcQTjEgsQ": "murder_total_deaths",
  "tOS388dWYzO1_FANUaiwKuA": "suicide_total_deaths",
  "tW9t1f9EQpvS4U04kWnk-og": "traffic_total_deaths",
  "pyj6tScZqmEd-30XcArAJHA": "debt_servicing_costs_percent_of_exports_and_net_income_from_abroad",
  "pyj6tScZqmEdQY2PxSsxxJQ": "external_debt_total_us_not_inflation_adjusted",
  "pyj6tScZqmEeUceHo3wTOaQ": "present_value_of_debt_percent_of_gni",
  "pyj6tScZqmEddLKU1fnfSSA": "exports_unit_value_index_2000100",
  "pyj6tScZqmEcL6zpB3Sj1Wg": "imports_unit_value_index_2000100",
  "pyj6tScZqmEe5SueBIj6eSw": "net_barter_terms_of_trade_2000_100",
  "0ArtujvvFrPjVdDRQTUhzNmVySlp6djRtLWh4eS1sNHc": "dead_kids_per_woman",
  "0ArtujvvFrPjVdGdFWmhqOEVXcUZha1hJWXAtWHlDSFE": "surviving_kids_per_woman",
  "0ArfEDsV3bBwCdDhjcXdjbURLMFFVcVFPYThhYmtvZXc": "20120905_extreme_poverty_percent_people_below_125_a_day",
  "0ArtujvvFrPjVdERlTHZFQ2ZaUkpySHpQMF82UmdlcUE": "alternative_gdp_per_capita_ppp_wb",
  "0ArtujvvFrPjVdEhhbV90QlJ3RFM0TW83dHd6RXBiX3c": "alternative_gdp_per_capita_ppp_pwt_7_1",
  "0ArfEDsV3bBwCdGhSY2trbEVpYnNsMENqendaVm5ucnc": "number_of_child_deaths",
  "0ArfEDsV3bBwCdFFjMFlMeS02N1NGNjJabl8wamVtdHc": "energy_supply_per_person_toe",
  "0ArfEDsV3bBwCdFdaNHA3R1BzcG9GSlkwMXdnMHpScVE": "energy_from_solid_biofuels_percent",
  "0ArfEDsV3bBwCdEV1RkJqTEItQnJYVXJlZzVuc3Y3Mmc": "residential_energy_use_percent",
  "0ArfEDsV3bBwCdG9jSHA0WklHU0dqUnBCVUpVOXFzQUE": "life_expectancy_at_birth_data_from_ihme",
  "0ArfEDsV3bBwCdFJCTm43NGc0SzdYeHBpZWZGb1V0ckE": "children_per_woman_temporary_update",
  "0ArfEDsV3bBwCdGhmWXFMY0hNcDFiTEt3ZVE4b21wRlE": "alternative_gdp_per_capita_ppp_pwt_8_0",
  "1iHsRp1TARpgh2CbuZGP5fj6jeY3Iz5cBLKTDlukVSHI": "life_expectancy_male",
  "12VhzicKNQCt8I4gmtBmulpfzDfnGjvFPr38g2wDcEGU": "life_expectancy_female",
  "1LklNUuBgFLNcGyG63kqjyze7N8_fDlNLAWZG4YKwJXc": "gdp_total_yearly_growth",
  "1tAnT0EeP67_DoOJCXb3Wd0hqjVMe0KWHPwUH22-BNmQ": "gdp_per_capita_yearly_growth",
  "16USvgw1H-rXCK0ZMmDkyMPd1FXQNpjCj6HCMIn-fmFQ": "yearly_co2_emissions_1000_tonnes",
  "1qOyLQJxO8zBHvldD1tS1s9dYIHuCe8u72JZQ_LjI9qI": "child_mortality_0_5_year_olds_more_years_version_7",
  "1edXplIOkF3Y5OuEWp_aVapd92-OD5aVFAPECZLgP8cA": "number_of_people_in_poverty",
  "1SKMoV61HUNwWfYNCZs2M9zgMWqVrs9YrbJxs5HQbJSQ": "income_per_person_long_series",
  "1S3Kcw96vp5-fZbaM7Lck_06IbJ8DagThGI5KHnzeSps": "newborn_mortality_rate_per_1000",
  "1iTvolhgIMta3MmsoPkGnewJiaybev3MvR9e_7mxZ48o": "newborn_deaths"
};

export default indicators;
