const geoproperties = {
  "CATID0": "world_6region",
  "CATID1": "income_groups",
  "CATID2": "landlocked",
  "CATID3": "g77_and_oecd_countries",
  "CATID4": "world_4region",
  "CATID5": "main_religion_2008"
};

export default geoproperties;
